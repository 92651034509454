import React, { useState, useEffect } from 'react';
import { Container, Grid, Link, Typography } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

import '../css/Footer.css';

export function Footer() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            if (window.innerWidth <= 600) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', checkIsMobile);
        checkIsMobile(); 

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return (
        <Container>
            <Grid container justifyContent="space-between" className="footer-container" alignItems="center">
                <Grid item>
                {isMobile ? null : (
                        <Typography>© {new Date().getFullYear()} SimulVR</Typography>
                    )}
                    <img
                        src="/simul_vr_logo.png"
                        alt="Crisis-VR"
                        width={30}
                    />
                </Grid>

                <Grid item>
                    <Grid container justifyContent="space-between" spacing={2} alignItems="center">
                        <Grid item>
                            <Link href="https://twitter.com/CrisisVR2023" color="inherit" className="link" target="_blank">
                                <TwitterIcon />
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="https://www.instagram.com/crisis.vr/" color="inherit" className="link" target="_blank">
                                <InstagramIcon />
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="https://www.feriadesoftware.cl" color="inherit" className="link" target="_blank">
                                <img   
                                    className='footer-software'
                                    src="https://www.feriadesoftware.cl/wp-content/themes/AA-fesw2017/img/fesw-logo-w.png" 
                                    alt="Feria de Software"
                                />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container justifyContent="space-between" spacing={2} alignItems="center">
                        <Grid item>
                            <Link href="https://www.bomberos.cl" color="inherit" className="link" target="_blank">
                                <img
                                    className='footer-bomberos'
                                    src="/logo-bomberos.png"
                                    alt="Academia Nacional de Bomberos de Chile"
                                />
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="https://www.anb.cl" color="inherit" className="link" target="_blank">
                                <img
                                    className='footer-bomberos'
                                    src="/logo-anb.png"
                                    alt="Academia Nacional de Bomberos de Chile"
                                />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
