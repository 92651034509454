// Create a Context object

import React from 'react';
import { createContext, useState } from 'react';

export const HomePageContext = createContext();

export const HomePageProvider = (props) => {
    const [windowHeight, setWindowHeight] = useState("100vh");

    return (
        <HomePageContext.Provider value={[windowHeight, setWindowHeight]}>
            {props.children}
        </HomePageContext.Provider>
    );
}