import React, { useEffect } from 'react';
import { CrisisVRNormalCard, CrisisVrTwitter } from '../components/index';
import { CRISIS_VR_FONT } from '../constants/index';

import { Container, Grid, Typography, Link } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import InstagramIcon from '@mui/icons-material/Instagram';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';


export function Contact() {

	useEffect(() => {
		// Enviar la página vista a Google Analytics cuando el componente se monte
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<Container style={{ 
			zIndex: 1,
			gap: isMobile ? '20px' : '0px',
		}}>
			<CrisisVrTwitter
				title={"Noticias en Twitter"}
				content={["Revisa las últimas noticias sobre Crisis-VR en Twitter."]}
				cardMedia={
					<TwitterTimelineEmbed
						sourceType="profile"
						screenName="CrisisVR2023"
						options={{ height: 360 }}
					/>
				}
			/>

			<CrisisVRNormalCard
				title={"Redes Sociales y Contacto"}
				backgroundColor={"#3E3A3A"}
				cardMedia={
					<Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", color: "white", marginTop: "1vh" }}>
						<Grid container justifyContent="center" spacing={2} alignItems="center">
							<Grid item xs={4}>
								<Typography variant="h6" sx={{ color: "white" }} fontFamily={CRISIS_VR_FONT} style={{
									fontSize: isMobile ? '12px' : '16px',
								}}>
									Redes Sociales:
								</Typography>
							</Grid>

							<Grid item>
								<Link href="https://twitter.com/CrisisVR2023" color="inherit" className="link" target="_blank">
									<TwitterIcon />
								</Link>
							</Grid>

							<Grid item>
								<Link href="https://www.instagram.com/crisis.vr/" color="inherit" className="link" target="_blank">
									<InstagramIcon />
								</Link>
							</Grid>

							<Grid item>
								<Link href="https://www.feriadesoftware.cl" color="inherit" className="link" target="_blank">
									<img
										src="https://www.feriadesoftware.cl/wp-content/themes/AA-fesw2017/img/fesw-logo-w.png"
										alt="Feria de Software"
										width={100}
									/>
								</Link>
							</Grid>

						</Grid>
						<Grid container justifyContent="center" spacing={2} alignItems="center" sx={{ marginTop: "1vh", marginBottom: "1vh" }}>
							<Grid item xs={4}>
								<Typography variant="h6" sx={{ color: "white" }} fontFamily={CRISIS_VR_FONT} style={{
									fontSize: isMobile ? '12px' : '16px',
								}}>
									Correo electrónico:
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="h6" sx={{ color: "white" }} fontFamily={CRISIS_VR_FONT} style={{
									fontSize: isMobile ? '12px' : '16px',
									fontWeight: 1000,
								}}>
									simulvrcompany@gmail.com
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				}
			/>
		</Container>

	);
}