import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


const NavButton = styled(Button)`
  && {
    width: 200px;
    height: 50px;
    background: white;
    text-transform: none;
    color: black;
    font-family: "Poppins", sans-serif;
    font-size: 1.0rem;
    transition: color 0.3s;

    border-radius: 25px;
    border: 1px solid black;

    &:hover {
      color: #C40404;
      font-weight: bold;
    }
  }
`;

export function Home() {
  const navigate = useNavigate(); 
  const [selectedButton, setSelectedButton] = useState(''); 

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleDiscoverClick = () => {
    navigate('/about');
    setSelectedButton('Sobre CrisisVR');
  };

  return (
    <Container style={{ zIndex: 1 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

		  position: 'fixed',
		  top: '50%',
		  left: '50%',
		  transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontWeight: 1000,
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          CrisisVR
        </Typography>

        <Typography
          variant="h5"
          component="p"
          sx={{
            color: '#FFFFFF',
            textAlign: 'center',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          Entrenamiento ante desastres a través de la
          <br />
          realidad virtual
        </Typography>

        <NavButton
          variant="contained"
          color="primary"
          sx={{
            marginTop: 5,
          }}
          onClick={handleDiscoverClick}
        >
          Descubrir cómo
        </NavButton>
      </div>
    </Container>
  );
}
