import React, { useEffect } from 'react';
import { ContactCard, CrisisVrContact, CrisisVRNormalCard } from "../components/index";

import { Container, Grid } from '@mui/material';

import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';


export function Team() {
	useEffect(() => {
		// Enviar la página vista a Google Analytics cuando el componente se monte
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<Container style={{
			zIndex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: isMobile ? '270px' : '20px',
		}}>
			<CrisisVrContact
				title={"SimulVR"}
				content={["SimulVR es una empresa que busca entregar soluciones de realidad virtual para la capacitación de personas en distintas áreas, con el fin de mejorar la experiencia de aprendizaje y reducir los costos asociados a la capacitación.",
					<br />,
					"Nuestro fin no es el de reemplazar la capacitación tradicional, sino que complementarla, entregando una experiencia de aprendizaje más inmersiva y realista, que permita a los usuarios aprender de manera más eficiente y segura.",
				]}
				cardMedia={
					<img
						src="/simul_vr_logo.png"
						alt="Academia Nacional de Bomberos de Chile"
						width={"50%"}
					/>
				}
			/>

			<CrisisVRNormalCard
				title={"Equipo de Desarrollo"}
				backgroundColor="transparent"
				cardMedia={
					<Grid container justifyContent="center" alignItems="center" spacing={1} sx={{
						marginTop: "0.1vh", 
						marginBottom: "3vh",
						flexDirection: isMobile ? 'column' : 'row',
						width: '100%'
					}}>
						<Grid item xs={10} md={6} lg={2.4}>
							<ContactCard
								name={"Bruno Vega"}
								title={"Developer / PO"}
								image={"/bruno_picture.jpeg"}
								linkedin={"https://www.linkedin.com/in/bruno-v-281819234/"}
							/>
						</Grid>
						<Grid item xs={10} md={6} lg={2.4}>
							<ContactCard
								name={"Diego Cuadra"}
								title={"Developer / SM"}
								image={"/diego_picture.jpeg"}
								linkedin={"https://www.linkedin.com/in/diego-cuadra-piutrín-9a9a82154/"}
							/>
						</Grid>
						<Grid item xs={10} md={6} lg={2.4}>
							<ContactCard
								name={"Fernando Delgado"}
								title={"Marketing - UX/UI"}
								image={"/freddy_picture.jpeg"}
								linkedin={"https://www.linkedin.com/in/fjdelgadofarias/"}

							/>
						</Grid>
						<Grid item xs={10} md={6} lg={2.4}>
							<ContactCard
								name={"Constanza Alvarado"}
								title={"Developer / Tec Lead"}
								image={"cony_picture.jpeg"}
								linkedin={"https://www.linkedin.com/in/constanza-beatriz-alvarado-b8a841275/"}

							/>
						</Grid>
						<Grid item xs={10} md={6} lg={2.4}>
							<ContactCard
								name={"Christian Ossa"}
								title={"Developer - UX/UI"}
								image={"/christian_picture.jpeg"}
								linkedin={"https://www.linkedin.com/in/christian-ossa-s/"}
							/>
						</Grid>
					</Grid>
				}
			/>
		</Container>
	);
}