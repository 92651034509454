import React from "react";
import { Card, CardContent, CardHeader, CardMedia, Grid, Typography, Stack } from "@mui/material";
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

import { CrisisVRButton } from "../constants/index";
import '../css/Index.css';


const isMobile = window.innerWidth <= 600;

export const CrisisVrCardVideo = ({ title, cardMedia, content, anotherMedia = null }) => {
    return (
        <div className="video-card">
            <Card sx={{
                backgroundColor: "#3E3A3A",
            }}>
                <CardHeader
                    sx={{
                        backgroundColor: "#C40404",
                        color: "#FFFFFF",
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 700,
                        textAlign: "center",
                        border: "5px",
                    }}
                    title={
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                fontFamily: 'Poppins, sans-serif',
                                textAlign: "center",
                                fontWeight: 700,
                                fontSize: isMobile ? '16px' : '20px',
                            }}
                        >
                            {title}
                        </Typography>
                    }
                />
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6} >
                        <CardMedia
                            component="div"
                            className="my-card-media"
                            sx={{
                                padding: '2vw',
                                marginTop: '2vw',
                                marginBottom: '2vw',
                            }}
                        >
                            {cardMedia}
                        </CardMedia>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardContent
                            sx={{
                                paddingTop: '2vh',
                                paddingBottom: '2vh',
                                paddingLeft: '2vw',
                                paddingRight: '2vw',

                            }}
                        >
                            {content.map((text, index) => (
                                <Typography
                                    key={index}
                                    variant="body1"
                                    component="div"
                                    sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins, sans-serif',
                                        textDecoration: 'none',
                                        fontSize: isMobile ? '12px' : '16px',
                                    }}
                                >
                                    {text}
                                </Typography>
                            ))}
                        </CardContent>
                    </Grid>
                </Grid>

                {anotherMedia && (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={6} md={6}>
                            <CardMedia
                                component="div"
                                sx={{
                                    paddingTop: "2vh",
                                    paddingBottom: "2vh",
                                    paddingLeft: "2vw",
                                    paddingRight: "2vw",
                                }}
                            >
                                {anotherMedia}
                            </CardMedia>
                        </Grid>
                    </Grid>
                )}
            </Card>
        </div>
    );
};

export const CrisisVrCard = ({ title, cardMedia, content, anotherMedia = null }) => {
    return (
        <div style={{ 
            height: '400px', 
            marginTop: isMobile ? '200px' : '8vh',
        }}>
            <Card sx={{
                backgroundColor: "#3E3A3A",
            }}>
                <CardHeader
                    sx={{
                        backgroundColor: "#C40404",
                        color: "#FFFFFF",
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 700,
                        textAlign: "center"
                    }}
                    title={
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                fontFamily: 'Poppins, sans-serif',
                                textAlign: "center",
                                fontWeight: 700,
                                fontSize: isMobile ? '16px' : '20px',
                            }}
                        >
                            {title}
                        </Typography>
                    }
                />
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6} >
                        <CardMedia
                            component="div"
                            className="photo"
                            sx={{
                                paddingLeft: '2vw',
                                paddingRight: '2vw',
                                textAlign: 'center',
                            }}
                        >
                            {cardMedia}
                        </CardMedia>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardContent
                            sx={{
                                paddingTop: '2vh',
                                paddingBottom: '2vh',
                                paddingLeft: '2vw',
                                paddingRight: '2vw',
                            }}
                        >
                            {content.map((text, index) => (
                                <Typography
                                    key={index}
                                    variant="body1"
                                    component="div"
                                    sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins, sans-serif',
                                        textDecoration: 'none',
                                        fontSize: isMobile ? '12px' : '16px',
                                    }}
                                >
                                    {text}
                                </Typography>
                            ))}
                        </CardContent>
                    </Grid>
                </Grid>

                {anotherMedia && (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} md={6}>
                            <CardMedia
                                component="div"
                                sx={{
                                    paddingTop: "2vh",
                                    paddingBottom: "2vh",
                                    paddingLeft: "2vw",
                                    paddingRight: "2vw",
                                }}
                            >
                                {anotherMedia}
                            </CardMedia>
                        </Grid>
                    </Grid>
                )}
            </Card>
        </div>
    );
};

export const CrisisVrCardV2 = ({ title, cardMedia, content }) => {
    return (
        <div style={{ 
            height: '400px',
            marginTop: isMobile ? '100px' : '0px',    
        }}>
            <Card sx={{
                backgroundColor: "#3E3A3A",
            }}>
                <CardHeader
                    sx={{
                        backgroundColor: "#C40404",
                        color: "#FFFFFF",
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 700,
                        textAlign: "center"
                    }}
                    title={
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 700,
                                textAlign: "center",
                                fontSize: isMobile ? '16px' : '20px',
                            }}
                        >
                            {title}
                        </Typography>
                    }
                />
                <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }} >
                    <Grid item xs={12} md={6}>
                        <CardContent sx={{
                            paddingTop: '2vh',
                            paddingBottom: '2vh',
                            paddingLeft: '2vw',
                            paddingRight: '2vw',
                        }}>
                            {content.map((text, index) => (
                                <Typography
                                    key={index}
                                    component="div"
                                    sx={{
                                        fontFamily: 'Poppins, sans-serif',
                                        color: "white",
                                        fontSize: isMobile ? '12px' : '16px',
                                    }}
                                >
                                    {text}
                                </Typography>
                            ))}
                        </CardContent>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CardMedia
                            component="div"
                            className="photo-center"
                            sx={{
                                marginTop: '2vw',
                                marginBottom: '2vw',
                                paddingLeft: "2vw",
                                paddingRight: "2vw"
                            }}
                        >
                            {cardMedia}
                        </CardMedia>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export const CrisisVrCardV3 = ({ title, cardMedia, content }) => {
    return (
        <div style={{ 
            height: '400px', 
            marginTop: isMobile ? '170px' : '4vh',
        }}>
            <Card sx={{
                backgroundColor: "#3E3A3A",
            }}>
                <CardHeader
                    sx={{
                        backgroundColor: "#C40404",
                        color: "#FFFFFF",
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 700,
                        textAlign: "center"
                    }}
                    title={
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 700,
                                textAlign: "center",
                                fontSize: isMobile ? '16px' : '20px',
                            }}
                        >
                            {title}
                        </Typography>
                    }
                />
                <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }} >
                    <Grid item xs={12} md={6}>
                        <CardContent sx={{
                            paddingTop: '2vh',
                            paddingBottom: '2vh',
                            paddingLeft: '2vw',
                            paddingRight: '2vw',

                        }}>
                            {content.map((text, index) => (
                                <Typography
                                    key={index}
                                    component="div"
                                    sx={{
                                        fontFamily: 'Poppins, sans-serif',
                                        color: "white",
                                        fontSize: isMobile ? '12px' : '16px',
                                    }}
                                >
                                    {text}
                                </Typography>
                            ))}
                        </CardContent>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <CardMedia
                            component="div"
                            className="photo"
                            sx={{
                                paddingLeft: "2vw",
                                paddingRight: "2vw"
                            }}
                        >
                            {cardMedia}
                        </CardMedia>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export const CrisisVrContact = ({ title, cardMedia, content, anotherMedia = null }) => {
    return (
        <div style={{ height: '400px', marginTop: '10vh' }}>
            <Card sx={{
                backgroundColor: "#3E3A3A",
            }}>
                <CardHeader
                    sx={{
                        backgroundColor: "#C40404",
                        color: "#FFFFFF",
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 700,
                        textAlign: "center"
                    }}
                    title={
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                fontFamily: 'Poppins, sans-serif',
                                textAlign: "center",
                                fontWeight: 700,
                            }}
                        >
                            {title}
                        </Typography>
                    }
                />
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6} >
                        <CardMedia
                            component="div"
                            sx={{
                                paddingTop: '4vh',
                                paddingBottom: '3.8vh',
                                paddingLeft: '2vw',
                                paddingRight: '2vw',
                                textAlign: 'center',
                            }}
                        >
                            {cardMedia}
                        </CardMedia>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardContent
                            sx={{
                                paddingTop: '2vh',
                                paddingBottom: '2vh',
                                paddingLeft: '2vw',
                                paddingRight: '2vw',

                            }}
                        >
                            {content.map((text, index) => (
                                <Typography
                                    key={index}
                                    variant="body1"
                                    component="div"
                                    sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins, sans-serif',
                                        textDecoration: 'none',
                                        fontSize: isMobile ? '12px' : '16px',
                                    }}
                                >
                                    {text}
                                </Typography>
                            ))}
                        </CardContent>
                    </Grid>
                </Grid>

                {anotherMedia && (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} md={6}>
                            <CardMedia
                                component="div"
                                sx={{
                                    paddingTop: "2vh",
                                    paddingBottom: "2vh",
                                    paddingLeft: "2vw",
                                    paddingRight: "2vw",

                                }}
                            >
                                {anotherMedia}
                            </CardMedia>
                        </Grid>
                    </Grid>
                )}
            </Card>
        </div>
    );
};

export const CrisisVrTwitter = ({ title, cardMedia, content, anotherMedia = null }) => {
    return (
        <div style={{
            height: '400px',
            marginTop: '10vh',
            marginBottom: '15vh',
        }}>
            <Card sx={{
                backgroundColor: "#3E3A3A",
            }}>
                <CardHeader
                    sx={{
                        backgroundColor: "#C40404",
                        color: "#FFFFFF",
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 700,
                        textAlign: "center"
                    }}
                    title={
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                fontFamily: 'Poppins, sans-serif',
                                textAlign: "center",
                                fontWeight: 700,
                                fontSize: isMobile ? '1px' : '16px',

                            }}
                        >
                            {title}
                        </Typography>
                    }
                />
                <Grid container alignItems="center">
                    <Grid item xs={12} md={6} >
                        <CardMedia
                            component="div"
                            sx={{
                                paddingTop: '4vh',
                                paddingBottom: '3.8vh',
                                paddingLeft: '2vw',
                                paddingRight: '2vw',
                                textAlign: 'center',
                            }}
                        >
                            {cardMedia}
                        </CardMedia>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardContent
                            sx={{
                                paddingTop: '2vh',
                                paddingBottom: '2vh',
                                paddingLeft: '2vw',
                                paddingRight: '2vw',

                            }}
                        >
                            {content.map((text, index) => (
                                <Typography
                                    key={index}
                                    variant="body1"
                                    component="div"
                                    sx={{
                                        color: 'white',
                                        fontFamily: 'Poppins, sans-serif',
                                        textDecoration: 'none',
                                        fontSize: isMobile ? '12px' : '16px',
                                    }}
                                >
                                    {text}
                                </Typography>
                            ))}
                        </CardContent>
                    </Grid>
                </Grid>

                {anotherMedia && (
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} md={6}>
                            <CardMedia
                                component="div"
                                sx={{
                                    paddingTop: "2vh",
                                    paddingBottom: "2vh",
                                    paddingLeft: "2vw",
                                    paddingRight: "2vw",
                                }}
                            >
                                {anotherMedia}
                            </CardMedia>
                        </Grid>
                    </Grid>
                )}
            </Card>
        </div>
    );
};


export const CrisisVRNormalCard = ({ title, cardMedia, backgroundColor }) => {
    return (
        <Card sx={{
            backgroundColor: backgroundColor,
            marginTop: "2vh",
            height: "100%",
            boxShadow: "none",
            width: "100%"
        }}>
            <CardHeader
                sx={{
                    backgroundColor: "#C40404",
                    color: "#FFFFFF",
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 700,
                    textAlign: "center"
                }}
                title={
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            fontFamily: 'Poppins, sans-serif',
                            textAlign: "center",
                            fontSize: isMobile ? '16px' : '20px',

                        }}
                    >
                        {title}
                    </Typography>
                }
            />
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "50%" }}>
                {cardMedia}
            </Grid>
        </Card>
    );
};


export const ContactCard = ({ image, name, title, email, linkedin, instagram = null }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                overflow: { xs: 'auto', sm: 'initial' },
            }}
        >
            <Card
                orientation="horizontal"
                sx={{
                    width: '100%',
                    backgroundColor: "#3E3A3A",
                }}
            >
                <Stack direction="column" spacing={0.5} sx={{
                    p: 1,
                    textAlign: 'center',
                    alignItems: 'center',
                }}>

                    <AspectRatio ratio={1} sx={{ width: "170px", height: "170px" }}>
                        <img
                            src={image}
                            alt={name}
                            style={{ width: "170px", height: "170px", objectFit: "cover" }}
                        />
                    </AspectRatio>

                    <CardContent sx={{
                        flex: '1 1 auto',
                        minWidth: 0,
                        p: 1,
                        color: "white",
                    }}>
                        <Typography variant="h5" fontWeight={5000} fontFamily={'Poppins, sans-serif'} style={{
                            fontSize: '13px',
                        }}>
                            {name}
                        </Typography>
                        <Typography variant="body1" fontWeight={300} fontFamily={'Poppins, sans-serif'} style={{
                            fontSize: '13px',
                        }}>
                            {title}
                        </Typography>
                        <Typography variant="body1" fontWeight={300} fontFamily={'Poppins, sans-serif'}>
                            {email}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1.5, '& > button': { flex: 1 }, paddingTop: "3vh" }}>
                            <CrisisVRButton href={linkedin} target="_blank"  style={{ flex: 1, width: '100%' }}>
                                <LinkedInIcon /> Linkedin
                            </CrisisVRButton>
                            {instagram && (
                                <CrisisVRButton href={instagram} target="_blank" >
                                    <InstagramIcon /> Instagram
                                </CrisisVRButton>
                            )}
                        </Box>
                    </CardContent>
                </Stack>
            </Card>
        </Box>
    );
};