import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import YouTube from 'react-youtube';

import { CrisisVrCard, CrisisVrCardV2, CrisisVrCardV3, CrisisVrCardVideo } from '../components/index';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';


import '../css/About.css';

export function About() {
  const videoId = 'fDIC3hMXbxY'; // El ID del video de YouTube

  useEffect(() => {
    // Enviar la página vista a Google Analytics cuando el componente se monte
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Container style={{ zIndex: 1 }}>
      <div style={{ position: 'relative' }}>
          {/* CrisisVrCard 1 */}
          <CrisisVrCardVideo
            title={"¿Qué es Crisis-VR?"}
            content={[
              "CrisisVR es una innovadora herramienta de capacitación en realidad virtual diseñada para bomberos. Su objetivo es transformar los procesos de formación de la Academia Nacional de Bomberos de Chile en una experiencia virtual interactiva y efectiva.",
              <br />,
              "Con esta tecnología, brindamos a los bomberos la oportunidad de practicar sus habilidades y aprender a enfrentar incendios en un entorno virtual seguro. ",
            ]}
            cardMedia={
              <YouTube
                opts={{
                  width: '100%',
                  height: '40%',
                  playerVars: {
                    rel: 0, // Desactivar videos relacionados
                    controls: 1, // Mostrar controles
                  },
                }}
                videoId={videoId}
              />
            }
          />

          {/* CrisisVrCardV2 2 */}
          <CrisisVrCardV2
            title={"¿Cómo funciona?"}
            content={[
              "Un casco de realidad virtual es como una puerta de entrada a un mundo 3D completamente inmersivo. Cuando un bombero lo usa, se encuentra instantáneamente en un entorno virtual que simula situaciones de combate de incendios de la vida real.",
              <br />,
              "Para que la experiencia sea aún más realista y efectiva, hemos aprovechado la tecnología de giroscopios en los controles de movimiento. Estos dispositivos permiten a los bomberos interactuar con el entorno virtual de una manera que se siente increíblemente auténtica."
            ]}
            cardMedia={
              <img
                src="/man-using-vr.png"
                alt="A man using a virtual reality console"
                width="60%"
              />
            }
          />

          <CrisisVrCard
            //make the title "Fisicas Realistas PROMETEO", but PROMETEO should be in a different color
            title={"Físicas Realistas"}
            content={[
              "El sistema de físicas Prometeo es una innovación que utiliza una potencia de cálculo limitada para simular sistemas termodinámicos y transferencia de calor en tiempo real.",
              <br />,
              "Este enfoque nos permite crear escenarios virtuales con interacciones extremadamente realistas, sin necesidad de utilizar recursos más allá de tu dispositivo local y un procesamiento vectorial mínimo en la nube."
            ]}
            cardMedia={
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/3/36/Large_bonfire.jpg"
                alt="Fire"
                width="100%"
              />
            }
          />

          <CrisisVrCardV3
            title={"Personajes No Jugadores"}
            content={[
              "Sabemos que el entrenamiento de bomberos es exigente y crucial. Los equipos de bomberos trabajan incansablemente para distribuir las tareas y garantizar un funcionamiento eficiente en situaciones de emergencia.",
              <br />,
              "En nuestro simulador, ofrecemos apoyo adicional al incorporar bomberos virtuales controlados por la máquina. Esto permite a los bomberos practicar de manera realista en situaciones de entrenamiento, mejorando sus habilidades y capacidad de respuesta."]}
            cardMedia={
              <img
                src="https://cdn.analyticsvidhya.com/wp-content/uploads/2023/08/What-is-M.Tech-in-Artificial-Intelligence_AI.jpg.optimal.jpg"
                alt="AI"
                width="100%"
              />
            }
          />
      </div>
    </Container>
  );
}
