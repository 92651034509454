import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';

import { Home } from './pages/Home';
import { About } from './pages/About';
import { Team } from './pages/Team';
import { Contact } from './pages/Contact';
import { NavBar } from './pages/NavBar';
import { Footer } from './pages/Footer';
import { FlameStack } from './constants/index';

import './css/Home.css';
import './css/NavBar.css';
import './css/Footer.css';




function App() {
	const [currentRoute, setCurrentRoute] = useState('');
	const location = useLocation();
	const [flameContainerHeight, setFlameContainerHeight] = useState('100vh');


	useEffect(() => {
		setCurrentRoute(location.pathname);

		if (isMobile) {
			if (location.pathname === '/'){
				setFlameContainerHeight('100vh');
			} else if (location.pathname === '/about') {
				setFlameContainerHeight('370vh');
			} else if (location.pathname === '/team') {
				setFlameContainerHeight('370vh');
			} else if (location.pathname === '/contact'){
				setFlameContainerHeight('160vh');
			}

		} else if (location.pathname === '/team') {
			setFlameContainerHeight('110vh');
		} else if (location.pathname === '/about'){
			setFlameContainerHeight('220vh');
		} else {
			setFlameContainerHeight('100vh');
		}

	}, [location.pathname, isMobile]);

	ReactGA.initialize('G-4F316NTE95'); // Reemplaza 'G-4F316NTE95' con tu propio ID de seguimiento

	return (
		<div className="root" data-route={currentRoute}>
			<NavBar currentRoute={currentRoute} />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/team" element={<Team />} />
				<Route path="/contact" element={<Contact />} />
			</Routes>
			<FlameStack flameContainerHeight={flameContainerHeight} />
			<footer className="footer">
				<Footer style={{ zIndex: 2 }} />
			</footer>
		</div>
	);
}

export default App;
