import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

// ------------------------------------- Export Constants ------------------------------------- //

export const CRISIS_VR_COLOUR = '#f6f1f1';
export const CRISIS_VR_COLOUR_DARK = '#6B0303';
export const CRISIS_VR_FONT = 'Segoe UI';

// ------------------------------------- Export Components ------------------------------------- //

const FlameIcon = () => {
	return (
		<div className="fire">
			<div className="fire-left">
				<div className="main-fire"></div>
				<div className="particle-fire"></div>
			</div>
			<div className="fire-center">
				<div className="main-fire"></div>
				<div className="particle-fire"></div>
			</div>
			<div className="fire-right">
				<div className="main-fire"></div>
				<div className="particle-fire"></div>
			</div>
			<div className="fire-bottom">
				<div className="main-fire"></div>
			</div>
		</div>
	);
}



  export const FlameStack = ({ flameContainerHeight }) => {
	return (
	  <div className="flame-container" style={{ height: flameContainerHeight }}> {/* Usa el tamaño dinámico */}
		<div className="circles">
		  {[...Array(10)].map((_, i) => (
			<FlameIcon key={i} />
		  ))}
		</div>
	  </div>
	);
  };



export const CrisisVRButton = styled(Button)`
	background-color: ${CRISIS_VR_COLOUR_DARK};
	color: white;
	font-family: ${CRISIS_VR_FONT};
	transition: background-color 0.3s ease-in-out;
	border-radius: 200px;
	border: 2px solid ${CRISIS_VR_COLOUR_DARK}; /* Agregamos borde */
	padding: 10px 20px; /* Ajusta los valores según tus necesidades */
	width: 100%;

	&:hover {
		background-color: ${CRISIS_VR_COLOUR};
		border-color: ${CRISIS_VR_COLOUR}; /* Cambiamos el color del borde al hacer hover */
	}
`;


  
  