import React, { useState } from 'react';
import { Box, Grid, IconButton, Menu, MenuItem, AppBar, Toolbar, Typography, Container, Button, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { Link } from 'react-router-dom';
import { CRISIS_VR_COLOUR } from '../constants/index';
import { styled } from '@mui/system';

import '../css/NavBar.css';
// import '../css/Components.scss';

const pageRoutes = {
    'Inicio': '/',
    'Sobre CrisisVR': '/about',
    '¿Quienes somos?': '/team',
    'Contacto': '/contact',
};

const AnimatedButton = styled(Button)`
    && {
        width: 170px;
        height: 50px;
        background: transparent;
        text-transform: none;
        font-family: "Poppins", sans-serif;
        font-size: 1.0rem;
        transition: color 0.3s;
        border-radius: 150px;
        &:hover {
            color: #C40404;
            font-weight: bold;
        }
    }
`;

export function NavBar({ currentRoute }) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [setSelectedButton] = useState('Inicio');

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    const handleButtonClick = (page) => {
        setSelectedButton(page);
    };

    return (
        <AppBar position="fixed" sx={{
            backgroundColor: CRISIS_VR_COLOUR,
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    <Typography variant="h6" noWrap component={Link} to="/" sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontWeight: 700,
                        color: 'inherit',
                        textDecoration: 'none',
                    }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <img
                                src="/LOGO_CRISIS_VR.png"
                                alt="Crisis-VR"
                                width={50}
                                sx={{
                                    mr: 2,
                                }}
                            />
                            <Typography variant="h6" sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 700,
                                color: 'black',
                            }}>
                                CrisisVR
                            </Typography>
                        </Stack>
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="black"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {Object.keys(pageRoutes).map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu} component={Link} to={page === 'Home' ? '/' : pageRoutes[page]}>
                                    {page}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Grid container justifyContent="center"  sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <Grid item>
                            <AnimatedButton
                                component={Link}
                                to="/"
                                onClick={() => handleButtonClick("/inicio")}
                                sx={{
                                    width: '100px',
                                    fontFamily: 'Poppins, sans-serif',
                                    fontWeight: 700,
                                    color: 'black',
                                    textDecoration: 'none',
                                }}
                            >
                                CrisisVR
                            </AnimatedButton>
                        </Grid>
                    </Grid>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end'}}>
                        {Object.keys(pageRoutes).map((page) => (
                            <AnimatedButton
                                component={Link}
                                to={pageRoutes[page]}
                                key={page}
                                onClick={() => handleButtonClick(page)}
                                sx={{
                                    color: currentRoute === pageRoutes[page] ? '#C40404' : 'black',
                                    fontWeight: currentRoute === pageRoutes[page] ? 'bold' : 'normal',
                                }}
                            >
                                {page}
                            </AnimatedButton>
                        ))}
                    </Box >
                </Toolbar>
            </Container>
        </AppBar>
    );
}
